<template>
<div :class="className">
    <div class="flex items-center justify-between mb-2">
      <label :htmlFor="name" class="font-semibold text-sm text-body">
        {{ label }}&nbsp;<span v-if="required" class="text-red-500">*</span>
      </label>
      <button
        v-if="forgotPageRouteOnClick"
        tabindex="-1"
        type="button"
        class="text-xs text-accent transition-colors duration-200 focus:outline-none focus:text-accent-hover focus:font-semibold hover:text-accent-hover"
        @click="forgotPageRouteOnClick"
      >
        {{ $t('common:text-forgot-password') }}
      </button>
    </div>
    <div class="relative">
      <input
        :id="name"
        ref="inputField"
        :name="name"
        :type="show ? 'text' : 'password'"
        :value="modelValue"
        :class="[ 'py-3 ps-4 pe-11 w-full rounded appearance-none transition duration-300 ease-in-out text-bolder text-sm focus:outline-none focus:ring-0', shadow ? 'focus:shadow' : '', variantClasses[variant], hasError ? '!border-red-500' : '', inputClassName ]"
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
        v-bind="$attrs"
        @input="$emit('update:modelValue', $event.target.value)"
        @keypress="onKeypress"
      >
      <label
        :htmlFor="name"
        class="absolute end-4 top-5 -mt-2 text-body cursor-pointer"
        @click="() => show = !show"
      >
        <EyeOffIcon v-if="show" class="w-6 h-6" />
        <EyeIcon v-else class="w-6 h-6" />
      </label>
    </div>
    <p v-if="hasError" class="my-2 text-xs text-red-500">{{ error }}</p>
  </div>
</template>

<script setup>
import EyeIcon from "@components/icons/eye-icon";
import EyeOffIcon from "@components/icons/eye-off-icon";

defineOptions({
  inheritAttrs: false
});

const variantClasses = {
  normal:
    "bg-gray-100 border border-border-base focus:shadow focus:bg-light focus:border-accent",
  solid:
    "bg-gray-100 border border-border-100 focus:bg-light focus:border-accent",
  outline: "border border-border-base focus:border-accent",
};

defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: String,
        default: ''
    },
    className: {
        type: String,
        default: ''
    },
    inputClassName: {
        type: String,
        default: ''
    },
    label: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    error: {
        type: String,
        default: ''
    },
    type: {
        type: String,
        default: 'text'
    },
    shadow: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    variant: {
        type: String,
        default: 'normal' // "normal" | "solid" | "outline"
    },
    forgotPageRouteOnClick: {
        type: Function,
        default: null
    }
});

const show = useState('password-input', () => false);

const { $eventBus } = useNuxtApp();
const inputField = ref(null);
const hasError = ref(!!props.error);

watch(() => props.error, (value) => hasError.value = !!value);

const onKeypress = () => {
  hasError.value = false;
}

const focusError = (value) => {
  nextTick(() => {
    if (props.name === value && !!props.error) {
        inputField.value.focus();
        hasError.value = true;
    }
  });
};

onMounted(async () => {
  $eventBus.on('focus:error', focusError);
});

onUnmounted(() => {
  $eventBus.off('focus:error', focusError);
});

</script>
