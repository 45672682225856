<template>
  <div class="pr-0 md:pr-5">
    <div ref="observedElement" class="w-full">
      <div class="w-full flex flex-row items-center justify-between space-x-2 mb-4 md:mb-8">
        <div class="w-full flex flex-wrap">
          <FilterTagCategory :data="variant" class="my-0.5" />
          <FilterTagParameters :data="variant" class-name="my-0.5" />
        </div>
        <VariantFavorite v-if="variant?.product?.status !== STATUS.CLOSED" :variant="variant" class="flex-shrink-0" />
      </div>

      <h1 class="w-full font-bold text-2xl tracking-tight text-primary-dark cursor-pointer transition-colors hover:text-primary-dark-hover no-underline hover:underline" :title="variant.name" @click="navigate">
        {{ localized(variant, 'name', variant.name, !showOriginal) }}
      </h1>

      <ProductDetailInfoPrices :variant="variant" />

      <div :class="['w-full', translating ? 'animate-pulse' : '']">
        <Truncate :lines="8" :value="localized(variant, 'content', '', !showOriginal)" format="html" />
      </div>
      <Alert v-if="translating" class="w-full mt-2" variant="light">
        <div class="flex flex-row space-x-2 items-center">
          <LanguageIcon class="w-4 h-4" />
          <div>{{ $t('text-translation-in-progress', { name: _.get(_.find(enums.languages, { locale: routeLang.locale.value }), 'name', '') }) }}</div>
        </div>
      </Alert>
      <Alert v-else-if="_.find(variant.contents || [], { isTranslated: true }) || _.find(variant.languages || [], { isTranslated: true })" class="w-full mt-2" variant="light">
        <div class="flex flex-row space-x-2 items-center">
          <LanguageIcon class="w-4 h-4" />
          <div v-if="!showOriginal">{{ $t('text-translated') }}</div>
          <button
            class="underline text-accent font-semibold transition-colors duration-200 focus:outline-none hover:text-accent-hover focus:text-accent-hover hover:no-underline"
            @click="() => showOriginal = !showOriginal"
          >
            {{ showOriginal ? $t('text-show-translation') : $t('text-show-original') }}
          </button>
        </div>
      </Alert>

      <div v-if="store.action !== 'HIDE_ADD_TO_CART' && isOrderable && variant?.product?.type !== PRODUCT_TYPE.WANTED" class="w-full">
        <div class="mt-4 flex flex-col">
          <span v-if="variant.stockCount" class="text-base text-body whitespace-nowrap">
            {{ isOrderable
              ? (variant.stockCount ? formatString(variant.stockCount, $t(variant?.product?.type === PRODUCT_TYPE.WANTED ? 'text-pieces-required' : 'text-pieces-available')) : '')
              : $t('text-out-stock')
            }}
          </span>
        </div>
      </div>

      <ProductDetailInfoActions :variant="variant" />
    </div>

    <div class="w-full flex flex-row items-center justify-between mb-9">
      <div class="flex flex-row items-center justify-starts space-x-2.5">
        <VariantViews :variant="variant" />
        <VariantLikes :variant="variant" />
        <MessagesBadge :variant="variant" :on-click="() => $emit('select:tab:comments', props.variant.id)" />
      </div>
      <ReportVariant :variant="variant" />
    </div>

    <VariantParameters :variant="variant" class="mb-9" />

    <VerifiedUser v-if="variant?.product?.status !== STATUS.CLOSED" :data="variant?.product" :type="CART_TYPE.SELLER" class="mb-9" />

    <div v-if="variant?.product?.status !== STATUS.CLOSED" class="w-full flex flex-row items-center justify-between">
      <h2 class="text-base text-primary-dark font-bold pb-3">
        {{ $t(variant?.product?.type === PRODUCT_TYPE.WANTED ? 'text-requester' : 'text-supplier') }}
      </h2>
      <Link
        :href="translatePath(ROUTES.CODE.SUPPLIER, slugify(variant?.product?.supplier?.id, variant?.product?.supplier?.name))"
        class="whitespace-nowrap text-xs underline hover:no-underline pb-3"
        @click="closeModal"
      >
        {{ $t('text-show-profile') }}
      </Link>
    </div>
    <ProfileHeader v-if="variant?.product?.status !== STATUS.CLOSED" :data="variant?.product" :type="CART_TYPE.SELLER" :show="{ rating: true }" class="mb-9" />

    <VariantMessages v-if="variant?.product?.status !== STATUS.CLOSED" :variant="variant" />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { PRODUCT_TYPE, CART_TYPE, STATUS } from "@utils/constants";
import { useEnumStore } from '@stores/enum';
import { useSelectedStore } from '@stores/selected';
import { formatString } from "@utils/format-string";
import Alert from "@components/ui/alert";
import LanguageIcon from "@components/icons/language-icon";
import ProfileHeader from "@components/profile/profile-header";
import VariantFavorite from "@components/product/product-details/variant-favorite";
import VariantViews from "@components/product/product-details/variant-views";
import VariantLikes from "@components/product/product-details/variant-likes";
import MessagesBadge from "@components/common/messages-badge";
import FilterTagCategory from "@components/filter/filter-tag-category";
import FilterTagParameters from "@components/filter/filter-tag-parameters";
import Truncate from "@components/ui/truncate";
import VariantParameters from "@components/product/product-details/variant-parameters";
import VerifiedUser from "@components/profile/verified-user";
import Link from "@components/ui/link/link";
import VariantMessages from "@components/product/product-details/variant-messages";
import ReportVariant from "@components/product/product-details/report-variant";
import ProductDetailInfoActions from "@components/product/product-details/product-detail-info-actions";
import ProductDetailInfoPrices from "@components/product/product-details/product-detail-info-prices";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  },
  size: {
    type: String,
    default: 'page' //page, modal
  }
})

const { $eventBus } = useNuxtApp();
const enums = useEnumStore();
const store = useSelectedStore();
const routeLang = useRouteLang();
const activityChecker = useActivityChecker();
const observedElement = ref(null);
const translating = ref(false);
const showOriginal = ref(false);

defineEmits(['select:tab:comments']);

const translateVariant = async () => {
  if (!activityChecker.wasUserActive.value) {
    return;
  }

  if ((!isLocalized(props.variant, 'name') && localized(props.variant, 'name')) || (!isLocalized(props.variant, 'content') && localized(props.variant, 'content'))) {
    translating.value = true;
    const result = await productVariantTranslationLoad(props.variant.product.id, props.variant.id);
    _.assignIn(props.variant, result);
    translating.value = false;
  } else if (!_.find(props.variant.languages || [], { isTranslated: false }) || !_.find(props.variant.contents || [], { isTranslated: false })) {
    const result = await productVariantTranslationLoad(props.variant.product.id, props.variant.id);
    _.assignIn(props.variant, result);
  }
}

watch(() => routeLang.locale.value, async () => {
  if (!translating.value) {
    await nextTick(translateVariant);
  }
});

watch(() => activityChecker.wasUserActive.value, async (value) => {
  if (value && !translating.value) {
    await nextTick(translateVariant);
  }
});

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const navigate = async () => {
    closeModal();
    await navigatorTo(translatePath(ROUTES.CODE.PRODUCT, slugify(props.variant?.id, props.variant?.name)));
}

const isOrderable = computed(() => {
  return _.get(props.variant, 'stockCount', 0) !== 0 && _.get(props.variant, 'availability.isProductOrderable', true);
});

const auctionUpdate = (value) => {
  if (props.variant?.auction?.id === value.id) {
    _.assignIn(props.variant.auction, value);
  }
};

onMounted(async () => {
  if (import.meta.client) {
    _.delay(translateVariant, 1000);
  }
  $eventBus.on('auction:update', auctionUpdate);
});

onUnmounted(() => {
  $eventBus.off('auction:update', auctionUpdate);
});

</script>
