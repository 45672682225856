<template>
    <SidebarWrapper>
      <div class="h-full max-h-full overflow-hidden">
        <Scrollbar class="w-full h-full max-h-screen">
          <div v-show="!categories.loaded" class="block xl:hidden">
            <div class="w-full mt-8 px-2">
              <CategoryLoader />
            </div>
          </div>
          <ErrorMessage v-show="!categories.loading && categories.error && categories.error?.message" :message="categories.error?.message" />
          <div v-show="categories.loaded && !categories.error && !categories.error?.message" class="py-3 px-6">
            <Search :label="$t('text-search-label')" variant="minimal" />
            <SidebarMenu type="" :items="categories[CATEGORY_TYPE.ESHOP]" :show-my="true" :is-detail="true" />
            <LocationFilter class="mb-5" :is-detail="true" />
            <FilterCategoryParameters :is-detail="true" />
            <WatchdogAddBtn :is-detail="true" />
            <SidebarMenu :type="CATEGORY_TYPE.NEWS" :items="categories[CATEGORY_TYPE.NEWS]" :show-all="false" class="py-8" />
            <FilterContentParameters :type="CATEGORY_TYPE.NEWS" :is-detail="true" />
            <SidebarMenu :type="CATEGORY_TYPE.CONTENT" :items="categories[CATEGORY_TYPE.CONTENT]" :show-all="false" class="py-8" />
          </div>
        </Scrollbar>
      </div>
    </SidebarWrapper>
</template>

<script setup>
import { CATEGORY_TYPE } from "@utils/constants";
import { useCategoryStore } from '@stores/category';
import SidebarWrapper from "@components/common/sidebar/sidebar-wrapper";
import CategoryLoader from "@components/ui/loaders/category-loader";
import ErrorMessage from "@components/ui/error-message";
import Scrollbar from "@components/ui/scrollbar";
import Search from "@components/common/search";
import SidebarMenu from "@components/ui/sidebar-menu";
import LocationFilter from "@components/filter/location-filter";
import FilterContentParameters from "@components/filter/filter-content-parameters";
import FilterCategoryParameters from "@components/filter/filter-category-parameters";
import WatchdogAddBtn from "@components/watchdog/watchdog-add-btn";

const categories = useCategoryStore();

</script>
