<template>
  <div class="w-full">
    <FilterParameter
      v-for="parameter in categoryParameters"
      :key="parameter.idParameter"
      :parameter="parameter"
      :is-detail="isDetail"
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useEnumStore } from '@stores/enum';
import FilterParameter from "@components/filter/filter-parameter";

defineProps({
  isDetail: {
    type: Boolean,
    default: false
  }
});

const enums = useEnumStore();
const route = useRoute();
const routeCategory = useRouteCategory();
const parameters = ref([]);

const selectedCategories = computed(() => _.chain(routeCategory.idCategory.value || '').split(',').map(value => parseInt(value)).filter().value());

const categoryParameters = computed(() => _.filter(parameters.value, item => _.some(item.idCategories, cat => _.includes(selectedCategories.value, cat))));

const reload = (items) => {
  parameters.value = setupEshopParameters(items);
}

onMounted(() => reload(enums.eshopParameters));

watch(() => enums.eshopParameters, (items) => reload(items));

watch(() => route.query, (query) => {
  _.forEach(parameters.value, parameter => {
    const queryKey = `p[${parameter.idParameter}]`;
    const values = _.get(query, queryKey, null);
    const checked = _.chain(values).split(',').filter().map(v => _.isString(v) && !isNaN(v) ? parseInt(v) : v).value();
    _.forEach(parameter.values, value => {
      value.value = _.includes(checked, value.id);
    });
  });
});

</script>
