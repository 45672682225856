<template>
  <div class="hidden" />
  </template>

<script setup>
import _ from 'lodash-es';
import { siteSettings } from "@settings/site.settings";

const props = defineProps({
  variant: {
    type: Object,
    required: true
  }
});

const config = useRuntimeConfig();

const structuredData = computed(() => {
  if (!props.variant) return null;

  return {
    "@context": "https://schema.org",
    "@type": "Article",
    "headline": localized(props.variant, 'name'),
    "description": localized(props.variant, 'description'),
    "articleBody": localized(props.variant, 'content').replace(/<[^>]+>/g, ' ').replace(/\s+/g, ' ').trim(),
    "author": {
      "@type": "Person",
      "name": props.variant.supplier.name,
      "url": `${config.public.appBaseUrl}${translatePath(ROUTES.CODE.SUPPLIER, slugify(props.variant?.supplier?.id, props.variant?.supplier?.name))}`
    },
    "datePublished": props.variant.publishedAt,
    "dateModified": props.variant.publishedAt,
    "mainEntityOfPage": {
      "@type": "WebPage",
      "@id": `post-${props.variant.id}`
    },
    "image": _.uniq([
      props.variant?.image?.url || _.get(props.variant, 'images.0.url', null) || `${config.public.appBaseUrl}${siteSettings.product.placeholderImage[config.public.layoutDesign]}`,
      ..._.map(props.variant?.images || [], image => image.url)
    ]),
    "url": `${config.public.appBaseUrl}${translatePath(ROUTES.CODE.NEWS, slugify(props.variant?.id, props.variant?.name))}`
  };
});

useHead({
  script: [
    {
      type: 'application/ld+json',
      children: JSON.stringify(structuredData.value, null, 2)
    }
  ]
});
</script>
