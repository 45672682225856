import _ from "lodash-es";

export default function (values) {
  return (
    _.chain(values)
      .cloneDeep()
      .transform((result, value, key) => {
        if (/^t\[\d+\]$/.test(key)) {
          const idParameter = parseInt(
            _.get(key.match(/^t\[(\d+)\]$/), "1", null)
          );
          result.parameters = _.get(result, "parameters", []);
          result.parameters.push({
            idParameter,
            idValues: _.chain(value)
              .split(",")
              .map((v) => (_.isString(v) && !isNaN(v) ? parseInt(v) : v))
              .value(),
          });
        } else {
          result[key] = null;
        }
      })
      .omitBy(_.isNull)
      .value()?.parameters || null
  );
}
