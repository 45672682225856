<template>
  <div v-for="idParameter in siteSettings.content.card.tags.parameters" :key="idParameter">
    <FilterTag
      v-for="item in _.filter(data?.parameters || [], item => item?.parameter?.id === idParameter)"
      :key="item?.id"
      :link="translatePath(routeCode) + `?t[${item.parameter.id}]=${item?.value?.id}`"
      :on-click="async () => await handleClick(item)"
      :class="className"
    >
      {{ item?.value?.value }}
    </FilterTag>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { ROUTES } from "@utils/routes";
import { siteSettings } from "@settings/site.settings";
import FilterTag from "@components/filter/filter-tag";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  data: {
    type: Object,
    required: true
  },
  className: {
    type: String,
    default: ''
  }
});

const { $eventBus } = useNuxtApp();

const routeCode = computed(() => {
  return {
    eshop: ROUTES.CODE.ADS,
    content: ROUTES.CODE.INSPIRATION,
    news: ROUTES.CODE.NEWS
  }[props.type] || ROUTES.CODE.SEARCH;
});

const closeModal = () => {
  $eventBus.emit('modal:close');
}

const handleClick = async (item) => {
  closeModal();
  await useSearchParam(`t[${item.parameter.id}]`, `${item?.value?.id}`);
}

</script>
