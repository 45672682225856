<template>
  <a :href="link" :aria-label="$t('text-search')" @click.prevent="onClick">
    <button
      v-tippy="$t('text-add-filter')"
      :class="['w-fit flex items-center justify-center font-normal text-primary-light rounded-full transition-colors bg-transparent border border-tag-border hover:border-tag-border-hover focus:border-tag-border-hover whitespace-nowrap mr-2', classesName]"
    >
      <slot />
    </button>
  </a>
</template>

<script setup>

const props = defineProps({
  size: {
    type: String,
    default: 'tiny' // "big" | "medium" | "tiny"
  },
  link: {
    type: String,
    default: '#'
  },
  onClick: {
    type: Function,
    default: () => {}
  }
});

const classes = {
  tiny: 'px-5 py-0 h-6 text-2xs',
  small: 'px-5 py-0 h-9 text-sm',
  medium: 'px-5 py-0 h-12',
  big: 'px-6 py-0 h-15',
};

const classesName = computed(() => {
    return [
        classes.root,
        props.size === 'tiny' ? classes.tiny : '',
        props.size === 'small' ? classes.small : '',
        props.size === 'medium' ? classes.medium : '',
        props.size === 'big' ? classes.big : '',
    ];
});

</script>
