<template>
  <div class="w-full">
    <FilterParameter
      v-for="parameter in typeParameters"
      :key="parameter.idParameter"
      :parameter="parameter"
      query-code="t"
      :is-detail="isDetail"
    />
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { useEnumStore } from '@stores/enum';
import FilterParameter from "@components/filter/filter-parameter";

const props = defineProps({
  type: {
    type: String,
    required: true,
  },
  isDetail: {
    type: Boolean,
    default: false
  }
});

const enums = useEnumStore();
const route = useRoute();
const parameters = ref([]);

const typeParameters = computed(() => _.filter(parameters.value, item => _.includes(item.types, props.type)));

const reload = (items) => {
  parameters.value = setupContentParameters(items);
}

onMounted(() => reload(enums.contentParameters));

watch(() => enums.contentParameters, (items) => reload(items));

watch(() => route.query, (query) => {
  _.forEach(parameters.value, parameter => {
    const queryKey = `t[${parameter.idParameter}]`;
    const values = _.get(query, queryKey, null);
    const checked = _.chain(values).split(',').filter().map(v => _.isString(v) && !isNaN(v) ? parseInt(v) : v).value();
    _.forEach(parameter.values, value => {
      value.value = _.includes(checked, value.id);
    });
  });
});

</script>
