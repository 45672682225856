<template>
    <a
        v-tippy="{ content: title, placement }"
        :href="path"
        :active-class="activeClass"
        :target="target"
    >
      <slot />
    </a>
</template>

<script setup>
import _ from 'lodash-es';

const props = defineProps({
    href: {
        type: String,
        default: ''
    },
    query: {
        type: Object,
        default: () => {}
    },
    hash: {
        type: String,
        default: ''
    },
    title: {
        type: String,
        default: ''
    },
    placement: {
        type: String,
        default: 'top'
    },
    activeClass: {
        type: String,
        default: ''
    },
    external: {
        type: Boolean,
        default: false
    },
    target: {
        type: String,
        default: '_self'
    }
});

const route = useRoute();

const path = computed(() => {
    const to = props.href || route.path;
    if (_.isNil(to) || to === undefined || to === 'undefined' || (typeof to === 'string' && _.isEmpty(to.trim()))) {
        sentryCaptureEvent({
            message: 'link.undefined',
            level: 'error',
            extra: { props, to }
        });

        return '/';
    }

    return `${to}${props.hash}`;
});

</script>
