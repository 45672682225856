<template>
    <div :id="id">
      <div v-if="showForm" class="mb-5" @click="() => checkAuth()">
        <div class="flex flex-row items-start justify-between space-x-2">
          <div class="relative w-full">
            <TextArea
              v-model="comment"
              name="comment"
              variant="outline"
              :placeholder="$t(placeholder)"
              class-name="w-full"
              :rows="1"
              :input-class-name="auth?.idSupplier ? 'pe-16' : 'pe-8'"
              :on-submit="handleSubmit"
              @cursor-changed="onCursorChanged"
            />
            <ChatEmojiPicker
              v-model="comment"
              :class="[windowSize?.width?.value < 1024 ? 'top-2' : 'top-3', auth.isLoggedIn ? 'end-10' : 'end-2']"
              :cursor-position="cursorPosition"
              @cursor-changed="onCursorChanged"
            />
            <ChatActionMenu
              v-if="auth?.isLoggedIn"
              v-model="comment"
              :images="images"
              :class="windowSize?.width?.value < 1024 ? 'top-2' : 'top-3'"
              :cursor-position="cursorPosition"
              @cursor-changed="onCursorChanged"
            />
          </div>
          <Button
            variant="submit"
            :loading="sending"
            :disabled="!sendingEnabled"
            :title="$t('text-send')"
            :size="windowSize?.width?.value < 1024 ? 'small' : 'medium'"
            :on-click="handleSubmit"
          >
            <SendIcon :class="windowSize?.width?.value < 1024 ? 'w-4 h-4' : 'w-6 h-6'" />
          </Button>
        </div>
        <div v-if="!!images.length" class="flex flex-wrap mt-2">
          <div
            v-for="(file, idx) in images"
            :key="idx"
            class="inline-flex flex-col overflow-hidden mt-2 me-2 relative"
          >
            <div class="flex-none w-24 h-24 sm:w-48 sm:h-48 mr-2 mb-2">
              <img :src="file.url" class="rounded object-cover w-24 h-24 sm:w-48 sm:h-48" loading="lazy" >
            </div>
          </div>
        </div>
      </div>
      <div
        v-for="(item, idx) in messages.items"
        :key="item.id"
        :class="[ 'w-full flex', auth.idCustomer === item?.customer?.id ? 'justify-end' : 'justify-start' ]"
      >
        <RightMessage
          v-if="auth.idCustomer === item?.customer?.id"
          :item="item"
          :prev-item="prevItem(idx)"
          :selected="selected"
        />
        <LeftMessage
          v-else
          :item="item"
          :prev-item="prevItem(idx)"
          :selected="selected"
          :is-read="canRead ? item.isRead : true"
          @read="readMessage"
        />
      </div>
      <div v-if="loading && ui.displayModal" class="w-full flex justify-center items-center h-48 bg-transparent relative">
          <Spinner :text="$t('common:text-loading')" />
      </div>
      <div v-if="hasNextPage || loadingMore" class="flex justify-center mt-8 lg:mt-12">
        <Button
            :loading="loadingMore"
            :disabled="loadingMore"
            class-name="text-sm md:text-base font-semibold h-11"
            @click="handleLoadMore"
        >
            {{ $t("text-load-more") }}
        </Button>
      </div>
    </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { CHAT_MESSAGE_TYPE } from "@utils/constants";
import { useAuthStore } from '@stores/auth';
import { useUIStore } from '@stores/ui';
import Spinner from "@components/ui/loaders/spinner/spinner";
import SendIcon from "@components/icons/send-icon";
import TextArea from "@components/ui/text-area";
import Button from "@components/ui/button";
import LeftMessage from "@components/chat/left-message";
import RightMessage from "@components/chat/right-message";
import ChatActionMenu from "@components/chat/chat-action-menu";
import ChatEmojiPicker from "@components/chat/chat-emoji-picker";

const props = defineProps({
  id: {
    type: String,
    default: null
  },
  parent: {
    type: Object,
    required: true
  },
  type: {
    type: String,
    required: true
  },
  fetchUrl: {
    type: String,
    required: true
  },
  loadError: {
    type: String,
    default: ''
  },
  postError: {
    type: String,
    default: ''
  },
  showForm: {
    type: Boolean,
    default: true
  },
  placeholder: {
    type: String,
    default: 'text-add-comment'
  },
  isAddBlocked: {
    type: Boolean,
    default: false
  }
});

const auth = useAuthStore();
const ui = useUIStore();

const { t } = useI18n();
const { $eventBus, $toast } = useNuxtApp();
const loading = useState(`chat-loading:${props.type}:${props.parent.id}`, () => false);
const loadingMore = ref(false);
const sending = ref(false);
const selected = ref(null);
const images = ref([]);
const comment = ref('');
const messages = useState(`chat-messages:${props.type}:${props.parent.id}`, () => ({ items: [] }));
const pagination = useState(`chat-pagination:${props.type}:${props.parent.id}`, () => ({ page: 1, pageCount: 1, itemsPerPage: 20, itemCount: 0 }));
const toRead = ref([]);
const cursorPosition = ref(0);

const windowSize = useWindowSize();

const addMessage = (data) => {
  if (!_.find(messages.value.items, { id: data.id })) {
    data.insertedAt = null;
    messages.value.items.unshift(data);
    $eventBus.emit(`${props.type}:update`, {
      id: props.parent.id,
      messagesCount: (props.parent?.messagesCount || 0) + 1
    });
  }
}

const hasNextPage = computed(() => pagination.value.page < pagination.value.pageCount);

const prevItem = (idx) => idx ? messages.value.items[idx - 1] : ({ });

const sendingEnabled = computed(() => {
  return auth.isLoggedIn && !sending.value && (!!comment.value.length || !!images.value.length);
});

const reload = async (pending = loading) => {
  if (pending.value) {
    return;
  }
  pending.value = true;
  try {
    const data = await $fetch(props.fetchUrl, {
      method: 'GET',
      params: {
        page: pagination.value.page,
        limit: pagination.value.itemsPerPage
      }
    });
    messages.value.items.push(...data?.items || []);
    pagination.value = data?.pagination || pagination.value;
  } catch (error) {
    if (import.meta.client) {
      $toast.error(t(props.loadError));
    }
    Sentry.captureException(error);
  }
  pending.value = false;
}

const handleLoadMore = async () => {
  pagination.value.page++;
  await reload(loadingMore);
}

const scrollToSelected = () => nextTick(() => {
  const element = document.querySelector(`[data-m-id='m-${selected.value}']`);
  if (element) {
    window.scrollTo({ top: (element?.offsetTop || 0) - 120, behavior: "smooth" });
  }
});

const handleLoadSelected = async () => {
  while (import.meta.client && selected.value && !_.find(messages.value?.items || [], { id: selected.value }) && (pagination.value.page < pagination.value.pageCount)) {
    await handleLoadMore();
  }
  scrollToSelected();
};

const reset = () => {
    comment.value = '';
    images.value = [];
}

const handleSubmit = async () => {
  if (props.isAddBlocked) {
    $toast.error(t('text-add-message-blocked-notice'));
    return;
  }
  sending.value = true;
  const body = {
    content: comment.value,
    images: images.value.map((image) => ({
      filename: image.name,
      content: image.encoded
    }))
  }
  try {
    const data = await $fetch(props.fetchUrl, {
      method: 'POST',
      body
    });
    reset();
    addMessage(data);
  } catch (error) {
    if (import.meta.client) {
      $toast.error(t(props.postError));
    }
    Sentry.captureException(error);
  }
  sending.value = false;
}

const messageAdd = (data) => {
  if (data.id === props.parent.id && data.type === props.type) {
    addMessage(data.message);
    selected.value = data.message.id;
    scrollToSelected();
  }
};

onServerPrefetch(async () => {
  await reload();
})

onMounted(async () => {
  if ((ui.displayModal || props.type === CHAT_MESSAGE_TYPE.CART) && (import.meta.server || navigator?.onLine)) {
    messages.value.items.splice(0, messages.value.items.length);
    _.assignIn(pagination.value, { page: 1, pageCount: 1, itemsPerPage: 20, itemCount: 0 });
    await reload();
  }
  if (import.meta.client && window?.location?.hash && window?.location?.hash.startsWith('#m-')) {
    selected.value = parseInt(window?.location?.hash.slice(3));
    await handleLoadSelected();
  }
  $eventBus.on('message:add', messageAdd);
});

onUnmounted(() => {
  $eventBus.off('message:add', messageAdd);
});

const updateToRead = _.debounce(async () => {
  const ids = toRead.value;
  toRead.value = [];

  try {
    const data = await $fetch(props.fetchUrl, {
      method: 'PUT',
      body: { action: 'read', ids }
    });
    auth.setNotificationInfo(data);
    $eventBus.emit(`${props.type}:update`, {
      id: props.parent.id,
      unreadMessagesCount: Math.max((props.parent?.unreadMessagesCount || 0) - ids.length, 0)
    });
    _.forEach(ids, id => {
      const item = _.find(messages.value.items, { id });
      if (item) {
        item.isRead = true;
      }
    });
  } catch (error) {
    Sentry.captureException(error);
  }
}, 2000);

const canRead = computed(() => props.fetchUrl.includes('/api/v1/eshop/checkout/cart/'));

const readMessage = (id) => {
  toRead.value.push(id);
  if (canRead.value) {
    updateToRead();
  }
}

const onCursorChanged = (value) => {
  cursorPosition.value = value;
}

</script>
