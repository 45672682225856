import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected';

export default async function () {
  const { $eventBus } = useNuxtApp();
  const store = useSelectedStore();

  if (store.action === 'ADD_TO_CART' && store.product?.id) {
    await cartAddItemToCart(store.product, 1);
    return false;
  } else if (store.action === 'ADD_NEW_AD') {
    await navigatorTo(translatePath(ROUTES.CODE.AD_ADD));
    return true;
  } else if (store.action === 'ADD_NEW_INSPIRATION') {
    await navigatorTo(translatePath(ROUTES.CODE.INSPIRATION_ADD));
    return true;
  } else if (store.action === 'REDIRECT_TO_HOME') {
    store.setAction(null);
    await navigatorTo(translatePath(ROUTES.CODE.HOME));
    return true;
  } else if (store.action === 'ADD_NEW_WATCHDOG') {
    store.setAction(null);
    $eventBus.emit("modal:open", "PRODUCT_WATCHDOG_ADD");
    return false;
  }

  return true;
}
