<template>
  <div class="min-h-40">
      <div class="w-full pt-4 border-t border-primary-neutral mb-32">
        <ul class="pb-4">
          <li>
            <button
              :class="['flex items-center w-full text-start outline-none font-bold focus:outline-none focus:ring-0 hover:underline', isDetail ? 'text-bolder' : 'text-heading', className ? className : 'text-base']"
              @click="handleClick"
            >
              <div class="flex justify-between items-center w-full cursor-pointer">
                <div>
                  {{ $t("text-setup-watchdog") }}
                </div>
                <div class="ms-auto">
                  <component :is="siteSettings.watchdog[config.public.layoutDesign].icon" class="w-6 h-6" />
                </div>
              </div>
            </button>
          </li>
        </ul>
      </div>
  </div>
</template>

<script setup>
import _ from "lodash-es";
import { useAuthStore } from "@stores/auth";
import { useSelectedStore } from "@stores/selected";
import { siteSettings } from "@settings/site.settings";

defineProps({
  className: {
    type: String,
    default: () => "",
  },
  isDetail: {
    type: Boolean,
    default: false
  }
});

const { $eventBus } = useNuxtApp();
const config = useRuntimeConfig();
const auth = useAuthStore();
const store = useSelectedStore();

const filters = ref({});

const handleClick = async () => {
  store.setWatchdogFilters(filters.value);
  $eventBus.emit("sidebar:close");
  $eventBus.emit("modal:close");
  if (auth.isLoggedIn) {
    $eventBus.emit("modal:open", "PRODUCT_WATCHDOG_ADD");
  } else {
    store.setAction('ADD_NEW_WATCHDOG');
    $eventBus.emit('modal:open', 'LOGIN_VIEW');
  }
};

const feedFiltersUpdate = (value) => {
  _.assignIn(filters.value, _.omit(value, ["sort", "direction"]));
};

onMounted(async () => {
  $eventBus.on("feed:filters:update", feedFiltersUpdate);
});

onUnmounted(() => {
  $eventBus.off("feed:filters:update", feedFiltersUpdate);
});
</script>
