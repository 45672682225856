
<template>
    <Popover class="absolute text-body">
      <PopoverButton>
        <span v-tippy="$t('text-insert-emoji')">
          <SmileIcon class="w-6 h-6" />
        </span>
      </PopoverButton>
      <Transition
        enter-active-class="transition duration-200 ease-out"
        enter-from-class="translate-y-1 opacity-0"
        enter-to-class="translate-y-0 opacity-100"
        leave-active-class="transition duration-150 ease-in"
        leave-from-class="translate-y-0 opacity-100"
        leave-to-class="translate-y-1 opacity-0"
      >
        <PopoverPanel :class="['absolute transform mb-2 py-2 z-60', panelOffset]">
            <ClientOnly>
                <EmojiPicker :native="true" :hide-search="true" :disable-skin-tones="true" :group-names="groupNames" @select="onSelectEmoji" />
            </ClientOnly>
        </PopoverPanel>
      </Transition>
    </Popover>
</template>

<script setup>
import 'vue3-emoji-picker/css';
import { defineAsyncComponent } from 'vue';
import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue';
import SmileIcon from "@components/icons/outline/smile-icon";

const EmojiPicker = import.meta.client ? defineAsyncComponent(() => import('vue3-emoji-picker')) : null;

const emit = defineEmits(['update:modelValue', 'cursorChanged']);

const props = defineProps({
  modelValue: {
    type: String,
    required: true
  },
  cursorPosition: {
    type: Number,
    default: 0
  },
  panelOffset: {
    type: String,
    default: '-right-28 sm:-right-9 bottom-8'
  }
});

const { t } = useI18n();

const groupNames = computed(() => ({
  "smileys_people": t('text-emoji-group-smileys-people'),
  "animals_nature": t('text-emoji-group-animals-nature'),
  "food_drink": t('text-emoji-group-food-drink'),
  "activities": t('text-emoji-group-activities'),
  "travel_places": t('text-emoji-group-travel-places'),
  "objects": t('text-emoji-group-objects'),
  "symbols": t('text-emoji-group-symbols'),
  "flags": t('text-emoji-group-flags')
}));

const onSelectEmoji = (emoji) => {
  emit('update:modelValue', props.modelValue.slice(0, props.cursorPosition) + emoji.i + props.modelValue.slice(props.cursorPosition));
  emit('cursorChanged', props.cursorPosition + emoji.i.length);
}

</script>
