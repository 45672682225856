export default function (props = {}) {
    const route = useRoute();

    const filters = ref({
        type: props?.productType ?? null,
        idSupplier: +(props?.idSupplier || route.query.idSupplier || null),
        idCategory: +(props?.idCategory || route.query.idCategory || null),
        isFavorite: props?.isFavorite ?? null,
        fulltext: route.query?.fulltext || null,
        location: route.query?.location || null,
        maxKmFromHome: route.query?.maxKmFromHome
            ? +route.query?.maxKmFromHome
            : null,
        maxKmFromLocation: route.query?.maxKmFromLocation
            ? +route.query?.maxKmFromLocation
            : null,
        parameters: transformEshopParameters(route.query),
        sort: route.query?.sort || null,
        direction: route.query?.direction || null,
    });

    return { filters };
}
