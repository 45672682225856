<template>
  <div :class="className">
    <label
      v-if="label"
      :htmlFor="name"
      class="flex items-center justify-between text-body-dark font-semibold text-sm leading-none mb-3 mt-0.5"
    >
      <div>
        {{ label }}&nbsp;<span v-if="required" class="text-red-500">*</span>
      </div>
      <div v-if="hintText" v-tippy="hintText">
        <QuestionCircle class="w-4 h-4 cursor-help" />
      </div>
    </label>
    <select
      :id="name"
      ref="inputField"
      :name="name"
      :placeholder="placeholder"
      :value="modelValue"
      :class="[ 'px-4 flex items-center w-full appearance-none transition duration-300 ease-in-out text-sm focus:outline-none', shadow ? 'focus:shadow' : '', variantClasses[variant], sizeClasses[dimension], disabled ? 'bg-gray-100 cursor-not-allowed' : '', hasError ? '!border-red-500 focus:ring-2 focus:ring-offset-2' : 'focus:ring-0', inputClassName, classBorder ]"
      :disabled="disabled"
      :required="required"
      autoComplete="off"
      autoCorrect="off"
      autoCapitalize="off"
      spellCheck="false"
      :aria-label="ariaLabel"
      :aria-invalid="hasError ? 'true' : 'false'"
      v-bind="$attrs"
      @input="onInput"
    >
      <option
        v-for="option in options"
        :key="option.id"
        :value="option.id"
        :selected="selectedOption?.id === option.id"
        :class="optionsClassName"
      >
        <span v-if="_.has(option, 'level')">
          &nbsp;
          <span v-for="(indent, idx) in _.times(option.level || 0, _.constant('&nbsp;'))" :key="idx" v-html="indent" />
        </span>
        {{ option.name }}
      </option>
    </select>
    <p v-if="hasError" class="my-2 text-xs text-red-500">
        {{ error }}
    </p>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import QuestionCircle from "@components/icons/outline/question-circle";

const variantClasses = {
  normal:
    "bg-gray-100 border-border-base focus:shadow focus:bg-light focus:border-accent",
  solid:
    "bg-gray-100 border-border-100 focus:bg-light focus:border-accent",
  outline: "border-border-base focus:border-accent",
  line: "ps-0 border-b border-border-base rounded-none focus:border-accent",
};

const sizeClasses = {
  tiny: "text-xs h-10",
  small: "text-sm h-10",
  medium: "h-12",
  big: "h-14",
};

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: [Number, String],
        default: null
    },
    options: {
        type: Array,
        default: () => []
    },
    className: {
        type: String,
        default: ''
    },
    classBorder: {
        type: String,
        default: 'border rounded'
    },
    inputClassName: {
        type: String,
        default: 'text-bolder'
    },
    optionsClassName: {
        type: String,
        default: 'text-bolder'
    },
    label: {
        type: String,
        default: ''
    },
    ariaLabel: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    placeholder: {
        type: String,
        default: ''
    },
    error: {
        type: String,
        default: ''
    },
    shadow: {
        type: Boolean,
        default: false
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    variant: {
        type: String,
        default: 'normal' // "normal" | "solid" | "outline" | "line"
    },
    dimension: {
        type: String,
        default: 'medium' // "tiny", small" | "medium" | "big"
    },
    hintText: {
      type: String,
      default: null
    }
});

const selectedOption = ref(_.find(props.options, { id: props.modelValue }) || props.options[0]);

emit('update:modelValue', selectedOption.value?.id);

const { $eventBus } = useNuxtApp();
const inputField = ref(null);
const hasError = ref(!!props.error);

watch(() => props.error, (value) => {
  hasError.value = !!value;
});

const onInput = (event) => {
  emit('update:modelValue', event.target.value);
  hasError.value = false;
}

const focusError = (value) => {
  nextTick(() => {
    if (props.name === value && !!props.error) {
        inputField.value.focus();
        hasError.value = true;
    }
  });
};

onMounted(async () => {
  $eventBus.on('focus:error', focusError);
});

onUnmounted(() => {
  $eventBus.off('focus:error', focusError);
});

</script>
