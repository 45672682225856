<template>
  <div>
    <div class="mr-5">
      <label
        htmlFor="images"
        class="text-body-dark font-semibold text-sm leading-tight mb-1.5"
      >
        <div class="truncate">{{ $t('text-insert-profile-image') }}</div>
      </label>
      <div class="flex flex-row items-center justify-center">
        <ImageInput
          v-model="modelValue.images"
          name="images"
          :preview="false"
        />
        <button
          v-if="modelValue.images?.length"
          v-tippy="$t('text-delete-avatar')"
          class="text-body hover:text-red-600"
          @click="deleteImages"
        >
          <Trash class="w-6 h-6" />
        </button>
      </div>
      <div v-if="modelValue.images?.length" class="relative flex items-center justify-center cursor-pointer overflow-hidden w-full h-32 mb-5">
        <div class="relative w-32 h-32">
          <Image
            :alt="_.get(modelValue.images, '0.name')"
            :src="_.get(modelValue.images, '0.url')"
            layout="fill"
            class-name="rounded-full"
          />
        </div>
      </div>
    </div>
    <div v-if="isCompany || !modelValue.company" class="flex flex-col">
      <Toggle
        v-model="isCompany"
        name="isCompany"
        :label="$t('text-add-company-info')"
      />
    </div>
    <Transition>
      <div v-if="isCompany || !!modelValue.company">
        <div class="flex flex-col">
          <Input
            v-model="modelValue.company"
            name="company"
            :label="$t('text-company')"
            :error="errors?.company?.message ? $t(errors.company.message) : ''"
            :required="isCompany || !!modelValue.company"
            variant="outline"
            class-name="w-full mb-5"
          />
        </div>
        <div class="flex flex-row">
          <Input
            v-model="modelValue.companyNo"
            name="companyNo"
            :label="$t('text-companyNo')"
            :error="errors?.companyNo?.message ? $t(errors.companyNo.message) : ''"
            :required="(isCompany || !!modelValue.company)"
            variant="outline"
            class-name="w-full mb-5 mr-5"
          />
          <Input
            v-model="modelValue.companyVatNo"
            name="companyVatNo"
            :label="$t('text-companyVatNo')"
            :error="errors?.companyVatNo?.message ? $t(errors.companyVatNo.message) : ''"
            variant="outline"
            class-name="w-full mb-5"
          />
        </div>
      </div>
    </Transition>
    <div class="flex-2 w-full mb-5 mr-5 relative">
      <Input
        v-model="modelValue.street"
        name="street"
        :label="$t('text-street')"
        :error="errors?.street?.message ? $t(errors.street.message) : ''"
        :required="true"
        variant="outline"
        class-name="w-full"
        :on-focus="showAddressOptions"
        :on-blur="hideAddressOptions"
      />
      <div v-if="showOptions && addressOptions?.length && !addressSelected" class="absolute left-0 mt-2 py-2 w-fit bg-light rounded-xl shadow-700 z-20">
        <Scrollbar class="w-full max-h-50" :options="{ scrollbars: { autoHide: 'never' } }">
          <ul>
            <li v-for="(item, idx) in addressOptions" :key="idx">
              <button
                class="block w-full py-2.5 px-6 text-sm text-start font-normal text-bolder transition duration-200 hover:text-light hover:bg-accent-hover focus:outline-none truncate"
                @click="() => handleAddressSelect(item)"
              >
                {{ item?.textovaAdresa || '' }}
              </button>
            </li>
          </ul>
        </Scrollbar>
      </div>
    </div>
    <div class="flex flex-row justify-between">
      <Input
        v-model="modelValue.streetNo"
        name="streetNo"
        :label="$t('text-streetNo')"
        :error="errors?.streetNo?.message ? $t(errors.streetNo.message) : ''"
        :required="true"
        variant="outline"
        class="flex-2"
        class-name="w-full mb-5 mr-5"
      />
      <Input
        v-model="modelValue.houseNo"
        name="houseNo"
        :label="$t('text-houseNo')"
        :error="errors?.houseNo?.message ? $t(errors.houseNo.message) : ''"
        variant="outline"
        class-name="w-full mb-5"
      />
    </div>
    <Input
      v-model="modelValue.city"
      name="city"
      :label="$t('text-city')"
      :error="errors?.city?.message ? $t(errors.city.message) : ''"
      :required="true"
      variant="outline"
      class-name="w-full mb-5"
    />
    <Input
      v-model="modelValue.zip"
      name="zip"
      :label="$t('text-zip')"
      :error="errors?.zip?.message ? $t(errors.zip.message) : ''"
      :required="true"
      :maxlength="10"
      :show-maxlength="false"
      variant="outline"
      class-name="w-full mb-5"
    />
    <Combobox
      v-model="modelValue.idCountry"
      :options="enums.countries"
      name="country"
      :label="$t('text-country')"
      :error="errors?.country?.message ? $t(errors.country.message) : ''"
      :required="true"
      variant="outline"
      class-name="w-full mb-5"
    />
    <AddressPhone :model-value="modelValue" :errors="errors" />
  </div>
</template>

<script setup>
import * as Sentry from "@sentry/vue";
import _ from 'lodash-es';
import { API_ENDPOINTS } from "@/utils/api/endpoints";
import { useEnumStore } from '@stores/enum';
import ImageInput from "@components/ui/image-input";
import Input from "@components/ui/input";
import Combobox from "@components/ui/combobox";
import Toggle from "@components/ui/toggle";
import AddressPhone from "@components/address/address-phone";
import Image from "@components/ui/image";
import Trash from "@components/icons/trash";
import Scrollbar from "@components/ui/scrollbar";

const props = defineProps({
    modelValue: {
        type: Object,
        required: true
    },
    errors: {
        type: Object,
        default: () => {}
    }
});

const config = useRuntimeConfig();
const enums = useEnumStore();
const isCompany = ref(!!props.modelValue?.company);
const showOptions = ref(false);
const addressOptions = ref([]);
const loadedAddress = ref(props.modelValue?.street || '');
const addressSelected = ref(false);

const deleteImages = () => {
  props.modelValue.images = [];
}

const loadAddresses = async (value) => {
  if (!value ||
    _.find(enums.countries, { id: props.modelValue.idCountry })?.code !== 'CZ' ||
    (_.isEqual(loadedAddress.value.toLowerCase().trim(), value.toLowerCase().trim()))
  ) {
    return;
  }
  try {
    const data = await $fetch(`${config.public.appBaseUrl}${API_ENDPOINTS.RUIAN}`, {
      method: 'GET',
      query: {
        textovaAdresa: value
      }
    });
    addressOptions.value = data?.standardizovaneAdresy || [];
    showOptions.value = true;
    loadedAddress.value = value;
  } catch (error) {
    addressOptions.value = [];
    Sentry.captureException(error);
  }
};

const updateCountry = (value, countries) => {
  if (!_.isEmpty(countries)) {
    if (!value.idCountry) {
      value.idCountry = _.get(_.head(countries), 'id');
    }
    if (!value.idCalling) {
      value.idCalling = _.get(_.head(countries), 'id');
    }
  }
}

watch(() => enums.countries, (value) => updateCountry(props.modelValue, value));

watch(() => props.modelValue, (value) => updateCountry(value, enums.countries));

watch(() => props.modelValue.street, _.debounce(loadAddresses, 500));

onMounted(() => {
  updateCountry(props.modelValue, enums.countries);
});

const handleAddressSelect = (item) => {
  props.modelValue.zip = `${item.psc || props.modelValue.zip || ''}`;
  props.modelValue.city = item?.nazevSpravnihoObvodu || item?.nazevMestskehoObvodu || item?.nazevMestskeCastiObvodu || item?.nazevObce || props.modelValue.city || '';
  props.modelValue.houseNo = `${item.cisloOrientacni || props.modelValue.houseNo || ''}`;
  props.modelValue.streetNo = `${item.cisloDomovni || props.modelValue.streetNo || ''}`;
  props.modelValue.street = item?.nazevUlice || item?.nazevCastiObce || props.modelValue.street || '';
  showOptions.value = false;
  addressOptions.value = [];
  addressSelected.value = true;
};

const showAddressOptions = _.debounce(() => {
  showOptions.value = !!addressOptions.value?.length;
}, 500);

const hideAddressOptions = _.debounce(() => {
  showOptions.value = false;
}, 500);

</script>
