export default function () {
    nextTick(() => {
      if (document.getElementById('cardsView')?.offsetTop) {
        window.scrollTo({ top: (document.getElementById('cardsView')?.offsetTop || 0) - 120, behavior: "smooth" });
      } else if (document.getElementById('productsView')?.offsetTop) {
        window.scrollTo({ top: (document.getElementById('productsView')?.offsetTop || 0) - 120, behavior: "smooth" });
      } else if (document.getElementById('newsView')?.offsetTop) {
        window.scrollTo({ top: (document.getElementById('newsView')?.offsetTop || 0) - 120, behavior: "smooth" });
      } else if (document.getElementById('contentView')?.offsetTop) {
        window.scrollTo({ top: (document.getElementById('contentView')?.offsetTop || 0) - 120, behavior: "smooth" });
      }
    });
}
