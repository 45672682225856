import _ from 'lodash-es';
import { API_ENDPOINTS } from "@utils/api/endpoints";
import { CONTENT_TYPE } from "@utils/constants";
import { useConfigStore } from '@stores/config';
import { useEnumStore } from '@stores/enum';
import { useCategoryStore } from '@stores/category';
import { useCustomerStore } from '@stores/customer';

export default async function () {
  const configs = useConfigStore();
  const enums = useEnumStore();
  const categories = useCategoryStore();
  const customer = useCustomerStore();
  const routeLang = useRouteLang();

  enums.setLoading(true);
  customer.setLoading(true);

  const { data, error, status } = await useApiFetch(API_ENDPOINTS.MASTER_PUBLIC, {
    params: {
      locale: routeLang.locale.value
    }
  });

  if (status.value === 'error') {
    sentryCaptureEvent({
      message: 'load-public-master',
      level: 'error',
      extra: { error: error.value }
    });
  } else {
    // configs
    configs.setPublicConfig(data.value?.configs?.items || []);

    // enums
    enums.setBanners(data.value?.banners || []);
    enums.setCountries(data.value?.countries || []);
    enums.setCurrencies(data.value?.currencies || []);
    enums.setLanguages(data.value?.languages || []);
    enums.setAvailabilities(data.value?.eshopAvailabilities || []);
    enums.setContentParameters(data.value?.contentParameters || []);
    enums.setEshopParameters(data.value?.eshopParameters || []);

    // categories
    categories.setEshop(data.value?.eshopCategories || []);
    categories.setContent(_.filter(data.value?.contentCategories || [], { type: CONTENT_TYPE.INSPIRATION }));
    categories.setNews(_.filter(data.value?.contentCategories || [], { type: CONTENT_TYPE.NEWS }));

    // visitor
    customer.setLanguage(data.value?.visitor?.language || {});
    customer.setCurrency(data.value?.visitor?.currency || {});
  }

  configs.setLoaded(true);
  enums.setLoading(false);
  categories.setLoaded(true);
  customer.setLoading(false);
}
