export default function (props = {}) {
    const route = useRoute();

    const filters = ref({
        idSupplier: +(props.idSupplier || route.query.idSupplier || null),
        idCategory: +(props.idCategory || route.query.idPostCategory || null),
        isFavorite: props.isFavorite,
        fulltext: route.query?.fulltext || null,
        parameters: transformContentParameters(route.query),
        sort: route.query?.sort || null,
        direction: route.query?.direction || null,
    });

    return { filters };
}
