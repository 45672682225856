<template>
    <div>
        <div class="flex items-center">
            <input
                :id="name"
                ref="inputField"
                :name="name"
                type="checkbox"
                :checked="modelValue"
                :class="[ 'focus:ring-0 focus:ring-transparent focus:ring-offset-transparent', sizeClasses[dimension], hasError ? 'border-red-500' : 'border-primary-dark', isDetail ? 'text-bolder' : 'text-bolder' ]"
                :disabled="disabled"
                :required="required"
                @change="onChange"
            >
            <label :htmlFor="name" :class="[ 'ms-2 text-base font-normal cursor-pointer hover:text-selected-item-hover', modelValue ? (isDetail ? 'text-primary-light' : 'text-selected-item') : (isDetail ? 'text-bolder' : 'text-heading') ]">
                {{ label }} <slot /> <span v-if="required" class="text-red-500">*</span>
            </label>
        </div>
        <p v-if="hasError" class="my-2 text-xs text-red-500">
            {{ error }}
        </p>
    </div>
</template>

<script setup>
const sizeClasses = {
  small: "w-4 h-4",
  medium: "w-6 h-6",
  big: "w-8 h-8",
};

const emit = defineEmits(['update:modelValue']);

const props = defineProps({
    modelValue: {
        type: Boolean,
        required: true
    },
    label: {
        type: String,
        default: ''
    },
    name: {
        type: String,
        default: ''
    },
    error: {
        type: String,
        default: ''
    },
    disabled: {
        type: Boolean,
        default: false
    },
    required: {
        type: Boolean,
        default: false
    },
    isDetail: {
        type: Boolean,
        default: true
    },
    dimension: {
        type: String,
        default: 'medium' // "small" | "medium" | "big"
    }
});

const { $eventBus } = useNuxtApp();
const inputField = ref(null);
const hasError = ref(!!props.error);

watch(() => props.error, (value) => hasError.value = !!value);

const onChange = (event) => {
    emit('update:modelValue', event.target.checked);
    hasError.value = false;
}

const focusError = (value) => {
  nextTick(() => {
    if (props.name === value && !!props.error) {
        inputField.value.focus();
        hasError.value = true;
    }
  });
};

onMounted(async () => {
  $eventBus.on('focus:error', focusError);
});

onUnmounted(() => {
  $eventBus.off('focus:error', focusError);
});

</script>
