<template>
  <div>
    <NuxtLayout>
      <NuxtLoadingIndicator />
      <NuxtPage />
    </NuxtLayout>
    <Toaster />
    <WsClient />
    <Notifications />
    <ManagedModal />
    <SidebarContainer />
  </div>
</template>

<script setup>
import { useUIStore } from '@stores/ui';
import { useCustomerStore } from '@stores/customer';
import ManagedModal from "@components/ui/modal/managed-modal";
import SidebarContainer from "@components/common/sidebar/sidebar-container";
import Notifications from "@components/ui/toast/notifications";
import Toaster from "@components/common/toaster";
import WsClient from "@components/common/events/ws-client";

const ui = useUIStore();
const customer = useCustomerStore();
const route = useRoute();
const routeLang = useRouteLang();

const notificationClicked = async (value) => {
  if (import.meta.client && value) {
    const regex = /#*.n=(?<id>\d*)/;
    const matches = `${value}`.match(regex);
    if (matches?.groups?.id) {
      await fcmNotificationConfirm(matches?.groups?.id, { clickedAt: new Date() });
    }
  }
}

watch(() => ui.displaySidebar, async (value) => {
  if (import.meta.client) {
    if (value) {
      document.body.classList.add("overflow-hidden");
    } else {
      document.body.classList.remove("overflow-hidden");
    }
  }
});

watch(() => route.hash, notificationClicked);

onServerPrefetch(async () => {
  await loadPublicMasterData();
  await loadMe();
  await loadContentMasterData();
  // await loadPublicConfig();
  // await loadLanguages();
  // await loadCurrencies();
  // await loadCategories();
  // await loadBanners();
});

onMounted(async () => {
  await loadMe();
  await loadContentMasterData();
  await customer.load();
  // await cartLoad();
  // await loadEshopParameters();
  // await loadContentParameters();
  // await loadCountries();
  // await loadEshopAvailabilities();
  await notificationClicked(window?.location?.hash);
});

useHead({
  htmlAttrs: {
    lang: routeLang.code.value,
  },
});

</script>
