import { ROUTES } from "@utils/routes";

export default function (content) {
  const config = useRuntimeConfig();

  const match = content.match(/:\s+\[([^\]]+)\]\(advert#(\d+)\)$/);
  if (match) {
    return [
      { type: 'text', content: content.replace(match[0], ': ') },
      { type: 'link', content: match[1], href: translatePath(ROUTES.CODE.PRODUCT, slugify(+match[2], match[1])) }
    ];
  }

  const domainPattern = config.public.appBaseUrl.replace(/^https?:\/\//, '').replace(/:\d+$/, '').replace(/\./g, '\\.');
  const domainRegex = new RegExp(`(https?:\\/\\/)?(${domainPattern}(?::\\d+)?\\/[^\\s.,]+)`, 'g');
  const domainStartRegex = new RegExp(`^${domainPattern}`);

  let result = [];
  let lastIndex = 0;

  content.replace(domainRegex, (match, _, link, offset) => {
      let actualMatch = match;
      let trailingChar = '';

      if (/[.,]$/.test(match)) {
          trailingChar = match.slice(-1);
          actualMatch = match.slice(0, -1);
      }

      if (offset > lastIndex) {
          result.push({ type: 'text', content: content.slice(lastIndex, offset) });
      }
      result.push({
        type: 'link',
        content: actualMatch.replace(/^https?:\/\//, ''),
        href: actualMatch.replace(domainStartRegex, '')
      });
      lastIndex = offset + match.length;

      if (trailingChar) {
          result.push({ type: 'text', content: trailingChar });
      }
  });

  if (lastIndex < content.length) {
      result.push({ type: 'text', content: content.slice(lastIndex) });
  }

  return result;
}
