<template>
  <div
    :class="[ 'lg:hidden w-full bg-light border-b border-gray-300 py-4 px-6 sticky top-20 z-10', cardClassName ]"
  >
    <div class="w-full flex flex-col items-center">
      <h3 :class="[ 'text-lg font-semibold text-bolder', supplier?.ratingScore || isBuyerBlocked ? 'mb-2' : 'mb-7', supplier?.logo ? '' : 'mt-3']">
        {{ supplier?.name }}
      </h3>

      <div v-if="supplier?.ratingScore" class="flex flex-row space-x-1 items-center mb-2">
          <StarRating size="small" :value="supplier?.ratingScore" />
          <div class="text-sm" v-text="supplier?.ratingScore" />
      </div>

      <div v-if="auth.isLoggedIn && auth?.idSupplier !== supplier?.id" class="w-full flex items-center justify-center mb-2">
        <SupplierFollow :supplier="supplier" />
      </div>

      <button
        class="text-sm font-semibold transition text-accent hover:text-accent-hover"
        @click="handleMoreInfoModal"
      >
        {{ $t('text-more-info')}}
      </button>
    </div>
  </div>

  <aside
    :class="[ 'bg-light rounded h-full w-full lg:w-80 2xl:w-96 hidden lg:block flex-shrink-0', className ]"
  >
    <div class="max-h-full overflow-hidden">
      <Scrollbar :class="[ 'w-full', styles.scrollbar_height ]">
        <div v-if="supplier.id === auth.idSupplier" class="text-right mt-7 mr-7">
          <Link :href="translatePath(ROUTES.CODE.PROFILE)" class="text-sm text-header hover:text-primary-dark-hover focus:outline-none focus:text-primary-dark-hover hover:underline" @click="closeModal">
            {{ $t('text-edit-profile') }}
          </Link>
        </div>

        <div class="w-full border-b border-gray-200 p-7 flex flex-col items-center">
          <div v-if="supplier?.logo" class="w-44 h-44 rounded-lg relative mx-auto border border-gray-100 bg-gray-200 overflow-hidden mb-8">
            <Image
              :alt="$t('text-logo')"
              :src="supplier?.logo"
              layout="fill"
              object-fit="cover"
              :width="174"
              :height="174"
              sizes="174px"
            />
          </div>

          <h3 :class="[ 'text-lg font-semibold text-bolder', supplier?.ratingScore || isBuyerBlocked ? 'mb-2' : 'mb-7', supplier?.logo ? '' : 'mt-3']">
            {{ supplier?.name }}
          </h3>

          <div v-if="supplier?.ratingScore" class="flex flex-row space-x-1 items-center mb-2">
              <StarRating size="small" :value="supplier?.ratingScore" />
              <div class="text-sm" v-text="supplier?.ratingScore" />
          </div>

          <Link
              v-if="isBuyerBlocked"
              :href="translatePath(ROUTES.CODE.SETTINGS_NOTIFICATION)"
              @click="closeModal"
          >
            <Badge
              :icon="UserBlock"
              :text="$t('text-blocked-user')"
              variant="error"
              class="mb-2"
            />
          </Link>

          <p v-if="localized(supplier, 'about')" class="text-sm text-body text-center leading-relaxed">
            <Truncate :lines="8" :value="localized(supplier, 'about')" class-name="text-sm text-body" />
          </p>

          <div v-if="auth.isLoggedIn && auth?.idSupplier !== supplier?.id" class="w-full flex items-center justify-center mt-5">
            <SupplierFollow :supplier="supplier" />
          </div>
        </div>

        <div class="p-7">
          <div v-if="location" class="mb-7 last:mb-0 flex flex-col">
            <span class="text-sm text-bolder font-semibold mb-2">
              {{ $t('text-location') }}
            </span>
            <div class="text-sm text-body">
              <span :class="supplier?.latitude && supplier?.longitude ? 'hover:underline cursor-pointer' : ''" @click="handleOpenLocation">{{ location }}</span>
            </div>
          </div>

          <div v-if="supplier?.distanceKm !== null" class="mb-7 last:mb-0 flex flex-col">
            <span class="text-sm text-bolder font-semibold mb-2">
              {{ $t('text-distance') }}
            </span>
            <div class="text-sm text-body">
              {{ supplier?.distanceKm }} {{ $t('text-unit-km') }}
            </div>
          </div>

          <div v-if="false" class="mb-7 last:mb-0 flex flex-col">
            <span class="text-sm text-bolder font-semibold mb-2">
              {{ $t('text-address') }}
            </span>
            <div v-for="(line, lid) in formatAddress(supplier?.billingAddress)" :key="lid" class="text-sm text-body">
              {{ line }}
            </div>
          </div>

          <div v-if="false" class="mb-7 last:mb-0 flex flex-col">
            <span class="text-sm text-bolder font-semibold mb-2">
              {{ $t('text-phone') }}
            </span>
            <a
              v-if="supplier?.billingAddress?.phone"
              :href="`tel:${supplier?.billingAddress?.calling?.callingCode ? '+' : ''}${supplier?.billingAddress?.calling?.callingCode || ''}${supplier?.billingAddress?.phone}`"
              class="text-sm underline text-body transition-colors duration-200 focus:outline-none hover:text-body-hover focus:text-body-hover hover:no-underline focus:no-underline"
              :aria-label="$t('text-phone')"
            >
              {{ supplier?.billingAddress?.phone ? `${supplier?.billingAddress?.phone}` : $t('text-no-contact') }}
            </a>
          </div>
          <div v-if="false && supplier?.billingAddress?.email" class="mb-7 last:mb-0 flex flex-col">
            <span class="text-sm text-bolder font-semibold mb-2">
              {{ $t('text-email') }}
            </span>
            <div class="flex items-center justify-between">
              <span class="text-sm text-body">
                {{ supplier?.billingAddress?.email }}
              </span>
              <a
                :href="`mailto:${supplier?.billingAddress?.email}`"
                target="_blank"
                class="text-sm text-accent font-semibold hover:text-accent-hover focus:outline-none focus:text-accent-hover"
                :aria-label="$t('text-email')"
              >
                {{ $t('text-write-email') }}
              </a>
            </div>
          </div>
          <div v-if="localized(supplier, 'delivery')" class="mb-7 last:mb-0 flex flex-col">
            <span class="text-sm text-bolder font-semibold mb-2">
              {{ $t('text-offered-delivery') }}
            </span>
            <div class="text-sm text-body">
              <Truncate :lines="8" :value="localized(supplier, 'delivery')" class-name="text-sm text-body" />
            </div>
          </div>
        </div>
        <SupplierRating :supplier="supplier" />
      </Scrollbar>
    </div>
  </aside>
</template>

<script setup>
import _ from 'lodash-es';
import styles from "./profile-card.module.css";
import { ROUTES } from "@utils/routes";
import { useSelectedStore } from '@stores/selected';
import { useCustomerStore } from '@stores/customer';
import { useAuthStore } from '@stores/auth';
import { useUIStore } from '@stores/ui';
import Scrollbar from "@components/ui/scrollbar";
import Image from "@components/ui/image";
import Truncate from "@components/ui/truncate";
import StarRating from "@components/common/star-rating";
import SupplierRating from "@components/profile/supplier-rating";
import UserBlock from "@components/icons/solid/user-block";
import Badge from "@components/ui/badge";
import Link from "@components/ui/link/link";
import SupplierFollow from "@components/profile/supplier-follow/supplier-follow";

const props = defineProps({
    supplier: {
        type: Object,
        default: () => { }
    },
    className: {
        type: String,
        default: ''
    },
    cardClassName: {
        type: String,
        default: ''
    }
})

const { $eventBus } = useNuxtApp();
const auth = useAuthStore();
const store = useSelectedStore();
const customer = useCustomerStore();
const ui = useUIStore();

const location = computed(() => _.join(_.filter([props.supplier?.billingAddress?.zip, props.supplier?.billingAddress?.city]), ' '));

const handleMoreInfoModal = () => {
    store.setSupplier(props.supplier);
    $eventBus.emit('modal:open', 'SHOP_INFO');
}

const closeModal = () => {
    $eventBus.emit('modal:close');
}

const isBuyerBlocked = computed(() => {
  return !!_.chain(customer)
    .get('supplier.blocked', [])
    .filter({ id: props?.supplier?.id })
    .value()
    .length;
});

const handleOpenLocation = () => {
  if (props.supplier?.latitude && props.supplier?.longitude) {
    if (ui.modalView && ui.displayModal) {
      store.setAction('RETURN_TO_SHOP_INFO');
      $eventBus.emit('modal:close');
    }
    store.setMapLocation(props.supplier);
    $eventBus.emit('modal:open', 'LOCATION_MAP_MODAL');
  }
}

</script>
