<template>
  <div class="bg-light w-screen md:max-w-md h-screen md:h-auto flex flex-col justify-center overflow-hidden">
    <Scrollbar class="py-6 px-5 sm:p-8 w-full max-h-screen">
        <h2 class="flex font-semibold text-lg md:text-xl text-bolder mb-2">
            {{ $t('text-adding-watchdog') }}
        </h2>
        <hr class="mb-2 md:mb-4">
        <ProductWatchdogForm v-model="watchdog" :errors="errors" />
        <Alert
          v-if="errorMsg"
          variant="error"
          :message="errorMsg"
          class="w-full mb-5"
          :closeable="true"
          :on-close="() => errorMsg = ''"
        />
        <div class="flex flex-col sm:flex-row items-center justify-between w-full">
            <Button
                class="sm:mr-3 mt-5 sm:mt-0 w-full sm:w-fit"
                variant="outline"
                :on-click="handleClose"
            >
                {{ $t('text-cancel') }}
            </Button>
            <Button
                class="mt-5 sm:mt-0 w-full sm:w-fit"
                :disabled="loading"
                :loading="loading"
                :on-click="handleSubmit"
            >
                {{ $t('text-save') }}
            </Button>
        </div>
    </Scrollbar>
  </div>
</template>

<script setup>
import _ from 'lodash-es';
import { PRODUCT_TYPE } from "@utils/constants";
import { useSelectedStore } from '@stores/selected';
import Scrollbar from "@components/ui/scrollbar";
import Button from "@components/ui/button";
import ProductWatchdogForm from "@components/watchdog/product-watchdog-form";
import Alert from "@components/ui/alert";

const { $eventBus } = useNuxtApp();
const store = useSelectedStore();
const geolocation = useGeolocation();
const loading = ref(false);
const errors = ref({});
const errorMsg = ref('');
const parameters = ref(_.chain(store.watchdogFilters.parameters)
    .map(item => _.chain(item.idValues).map(idValue => ({ parameter: { id: item.idParameter }, value: { id: idValue } })).value())
    .flatten(1)
    .value());
const watchdog = ref({
    idCategory: store.watchdogFilters.idCategory ? +store.watchdogFilters.idCategory : null,
    type: _.includes(PRODUCT_TYPE, store.watchdogFilters.type) ? store.watchdogFilters.type : null,
    fulltext: store.watchdogFilters.fulltext ? store.watchdogFilters.fulltext : null,
    maxKmFromHome: store.watchdogFilters.maxKmFromHome ? +store.watchdogFilters.maxKmFromHome : null,
    maxKmFromLocation: store.watchdogFilters.maxKmFromLocation ? +store.watchdogFilters.maxKmFromLocation : null,
    location: store.watchdogFilters.location ? store.watchdogFilters.location : null,
    latitude: geolocation.coordinates.value.latitude || store.coordinates.latitude || null,
    longitude: geolocation.coordinates.value.longitude || store.coordinates.longitude || null,
    parameters: setupEshopParameters(parameters.value, _.chain(parameters.value).map(parameter => parameter?.value?.id).value())
});

const handleClose = () => {
  $eventBus.emit('modal:close');
};

const isValid = () => {
  errors.value = {};
  errorMsg.value = '';
  if (watchdog.value.location && !watchdog.value.maxKmFromLocation) {
    errors.value.maxKmFromLocation = { message: 'error.watchdog.maxKmFromLocation.required' };
  }
  if (!_.every(_.values(errors.value), _.isEmpty)) {
    errorMsg.value = 'error-some-fields-required';
    $eventBus.emit('focus:error', _.chain(errors.value)
      .keys()
      .first()
      .value()
    );
    return false;
  }

  return true;
}

const handleSubmit = async () => {
  if (!isValid()) {
    return false;
  }
  loading.value = true;

  const body = {
    idCategory: watchdog.value.idCategory ? +watchdog.value.idCategory : null,
    type: _.includes(PRODUCT_TYPE, watchdog.value.type) ? watchdog.value.type : null,
    fulltext: watchdog.value.fulltext ? watchdog.value.fulltext : null,
    parameters: _.chain(watchdog.value.parameters || [])
        .filter(item => watchdog.value.idCategory ? _.some(item.idCategories, cat => cat === +watchdog.value.idCategory) : true)
        .map(item => ({
            idParameter: item.idParameter,
            idValues: _.chain(item.values).filter(value => value.value).map(value => value.id).value()
        })).filter(item => !_.isEmpty(item.idValues)).value()
  };

  if (watchdog.value.maxKmFromLocation && watchdog.value.latitude && watchdog.value.longitude) {
    _.assignIn(body, {
      maxKmFromLocation: watchdog.value.maxKmFromLocation ? +watchdog.value.maxKmFromLocation : null,
      location: watchdog.value.location ? watchdog.value.location : null,
      latitude: +watchdog.value.latitude ? +watchdog.value.latitude : null,
      longitude: +watchdog.value.longitude ? +watchdog.value.longitude : null,
    });
  } else {
    _.assignIn(body, {
      maxKmFromHome: watchdog.value.maxKmFromHome ? +watchdog.value.maxKmFromHome : (watchdog.value.maxKmFromLocation ? +watchdog.value.maxKmFromLocation : null),
    });
  }

  const data = await productWatchdogCreate(body);
  if (data) {
    _.assignIn(store.productWatchdog, data);
    handleClose();
    $eventBus.emit('toast:success', 'success.product-watchdog.create');
  } else {
    $eventBus.emit('toast:error', 'error.product-watchdog.create');
  }
  loading.value = false;
}

</script>
